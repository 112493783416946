// Basic Colors
$navy: #1ab394;       // Primary color
$dark-gray: #c2c2c2;  // Default color
$blue: #1c84c6;       // Success color
$lazur: #23c6c8;      // Info color
$yellow: #f8ac59;     // Warning color
$red: #ED5565;        // Danger color

// Various colors
$text-color: #676a6c;   // Body text
$gray: #f3f3f4;         // Background wrapper color
$light-gray: #D1DADE;   // Default label, badge
$label-badge-color: #5E5E5E;
$light-blue:#f3f6fb;

// Spinner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

//Basics
$basic-link-color: #337ab7;

// IBOX colors ( default panel colors)
$border-color: #e7eaec;     // IBox border
$ibox-title-bg:#ffffff;     // IBox Background header
$ibox-content-bg:#ffffff;   // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Assets path
$asset-path : "~/assets/styles/patterns";

// Boxed layout width
$boxed-width: 1200px;
$boxed-background: image-url('#{$asset-path}/shattered.png');

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2F4050;
$nav-profile-pattern: image-url("#{$asset-path}/header-profile.png");
$nav-text-color: #a7b1c2;